'use client';

import PlusIcon from '/public/svg/add.svg';
import MinusIcon from '/public/svg/minus.svg';
import CheckIcon from '/public/svg/check.svg';
import styles from './MyListButton.module.scss';
import { useAtom } from 'jotai';
import { userProfile } from '@/lib/atoms/profile';
import { addFavoriteShow, addFavoriteVideo, removeFavoriteShow, removeFavoriteVideo } from '@/lib/helpers/favorites';
import { PersonalFavoriteShowsContent, PersonalFavoriteVideosContent, ProfileData } from '@/lib/types/api/profile-data';
import { useEffect, useState } from 'react';
import MyListButtonProps from '@/components/Button/MyList/MyListTypes';
type Profile = ProfileData | undefined | null;
const SignedInMyListButton = (props: MyListButtonProps) => {
  const {
    video,
    show,
    style = 'button'
  } = props;
  let profile: Profile = props?.profileStub;
  if (!profile) {
    // The below line is exceptional for the case of the lint in that var is the
    // proper use case for block scoping setProfile outside of the if block
    var [atomProfile, setProfile] = useAtom(userProfile); // eslint-disable-line no-var, react-hooks/rules-of-hooks
    profile = atomProfile;
  }
  const [isInList, setIsInList] = useState(false);
  useEffect(() => {
    if (show) {
      const favoriteShows = (profile?.personal_data.favorite_shows.content as PersonalFavoriteShowsContent[]);
      setIsInList(favoriteShows.some(s => s.id === show.slug));
    } else if (video) {
      const favoriteVideos = (profile?.personal_data.favorite_videos.content as PersonalFavoriteVideosContent[]);
      setIsInList(favoriteVideos.some(v => v.id === video.slug));
    }
  }, [profile]);
  const removeFavoriteShowFromStorage = (slug: string) => {
    const newProfile = ({
      ...profile
    } as ProfileData);
    const content = (newProfile.personal_data.favorite_shows.content as PersonalFavoriteShowsContent[]);
    const filteredContent = content.filter(x => x.id !== slug);
    newProfile.personal_data.favorite_shows.content = filteredContent;
    setIsInList(false);
    setProfile(newProfile);
  };
  const addFavoriteShowToStorage = (slug: string) => {
    const newProfile = ({
      ...profile
    } as ProfileData);
    newProfile.personal_data.favorite_shows.content.push({
      id: slug
    });
    setIsInList(true);
    setProfile(newProfile);
  };
  const removeFavoriteVideoFromStorage = (slug: string) => {
    const newProfile = ({
      ...profile
    } as ProfileData);
    const content = (newProfile.personal_data.favorite_videos.content as PersonalFavoriteVideosContent[]);
    const filteredContent = content.filter(x => x.id !== slug);
    newProfile.personal_data.favorite_videos.content = filteredContent;
    setIsInList(false);
    setProfile(newProfile);
  };
  const addFavoriteVideoToStorage = (slug: string) => {
    const newProfile = ({
      ...profile
    } as ProfileData);
    newProfile.personal_data.favorite_videos.content.push({
      id: slug
    });
    setIsInList(true);
    setProfile(newProfile);
  };
  const clicked = async () => {
    if (show) {
      if (isInList) {
        await removeFavoriteShow(show.cid);
        removeFavoriteShowFromStorage(show.slug);
      } else {
        await addFavoriteShow(show.cid);
        addFavoriteShowToStorage(show.slug);
      }
    } else if (video) {
      if (isInList) {
        await removeFavoriteVideo(video.cid);
        removeFavoriteVideoFromStorage(video.slug);
      } else {
        await addFavoriteVideo(video.cid);
        addFavoriteVideoToStorage(video.slug);
      }
    }
  };
  let content = <></>;
  if (isInList) {
    if (style === 'iconOnly') {
      content = <>
        <CheckIcon />
        <span className="visuallyhidden">Remove from My List</span>
        </>;
    } else if (style === 'kabobMenu') {
      content = <>
        <MinusIcon />
        <span>Remove from My List</span>
        </>;
    } else {
      content = <>
        <CheckIcon />
        <span className="visuallyhidden">Remove from</span>
        <span>My List</span>
        </>;
    }
  } else {
    if (style === 'iconOnly') {
      content = <>
        <PlusIcon />
        <span className="visuallyhidden">Add to My List</span>
        </>;
    } else if (style === 'kabobMenu') {
      content = <>
        <PlusIcon />
        <span>Add to My List</span>
        </>;
    } else {
      content = <>
        <PlusIcon />
        <span className="visuallyhidden">Add to</span>
        <span>My List</span>
        </>;
    }
  }
  let buttonClass = styles.my_list_button;
  if (style === 'iconOnly') {
    buttonClass = styles.my_list_button__icon_only;
  } else if (style === 'kabobMenu') {
    buttonClass = styles.my_list_button__kabob_menu;
  }
  return <button className={buttonClass} onClick={clicked} data-sentry-component="SignedInMyListButton" data-sentry-source-file="SignedInMyListButton.tsx">
      {content}
    </button>;
};
export default SignedInMyListButton;